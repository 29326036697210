import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';

import './styles/logistics.scss';

export default function Logistics() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "logistics" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                signup {
                  alert_text
                  launch_modal_button_text
                  legal_text
                  modal_submit_button_text
                  modal_title
                  unique_id
                }
                background_desktop_image {
                  source_url
                  alt_text
                }
                background_mobile_image {
                  source_url
                }
              }
              health_care {
                problem {
                  title
                  copy
                }
                pitch_banner_1 {
                  title
                  copy
                  screenshot {
                    source_url
                  }
                }
                pitch_banner_2 {
                  title
                  copy
                  screenshot {
                    source_url
                  }
                }
                pitch_banner_3 {
                  title
                  copy
                  screenshot {
                    source_url
                  }
                }
                platform {
                  header
                  desktop {
                    device {
                      source_url
                    }
                    title
                    list_title
                    list_items {
                      list_item_1
                      list_item_2
                      list_item_3
                      list_item_4
                    }
                  }
                  mobile {
                    device {
                      source_url
                    }
                    title
                    list_title
                    list_items {
                      list_item_1
                      list_item_2
                      list_item_3
                      list_item_4
                    }
                  }
                }
                businesses {
                  business {
                    industry
                    logo {
                      source_url
                      alt_text
                    }
                    company
                    quote
                    name
                    position
                    read_more_link
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContent = data.allWordpressPage.edges[0].node.acf.health_care;
  const hero = data.allWordpressPage.edges[0].node.acf.hero;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="lp-logistics-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } className="hero intercom-launch-demo" />

        <section className="section">
          <div className="container">
            <h2 className="title has-text-centered">
              {pageContent.problem.title}
            </h2>
            <p
              className="body"
              dangerouslySetInnerHTML={ { __html: pageContent.problem.copy } }
            />
          </div>
        </section>

        <section className="section ">
          <div className="container">
            <div className="columns">
              <div className="column is-full has-text-centered">
                <h2 className="title ">{pageContent.header}</h2>
              </div>
            </div>

            <div className="pitch-banner columns is-vcentered">
              <div className="column is-half">
                <img
                  loading="lazy"
                  src={ pageContent.pitch_banner_1.screenshot.source_url }
                  alt="screenshot of When I Work scheduler"
                />
              </div>
              <div className="column is-half">
                <h2 className="title">{pageContent.pitch_banner_1.title}</h2>
                <p>{pageContent.pitch_banner_1.copy}</p>
              </div>
            </div>

            <div className="pitch-banner columns is-vcentered is-reverse">
              <div className="column is-half">
                <img
                  loading="lazy"
                  src={ pageContent.pitch_banner_2.screenshot.source_url }
                  alt="screenshot of When I Work payroll and labor reporting"
                />
              </div>
              <div className="column is-half">
                <h2 className="title">{pageContent.pitch_banner_2.title}</h2>
                <p>{pageContent.pitch_banner_2.copy}</p>
              </div>
            </div>

            <div className="pitch-banner columns is-vcentered">
              <div className="column is-half">
                <img
                  loading="lazy"
                  src={ pageContent.pitch_banner_3.screenshot.source_url }
                  alt="screenshot of When I Work job sites functionality"
                />
              </div>
              <div className="column is-half">
                <h2 className="title">{pageContent.pitch_banner_3.title}</h2>
                <p>{pageContent.pitch_banner_3.copy}</p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section device-comparison"
        >
          <div className="container">
            <div className="columns is-multiline is-vcentered">
              <div className="column is-full has-text-centered">
                <h2 className="title ">{pageContent.platform.header}</h2>
              </div>
              <div className="column is-5">
                <div className="has-padding-bottom-lg">
                  <img
                    className="laptop"
                    src={
                      pageContent.platform.desktop.device.source_url
                    }
                    role="presentation"
                  />
                </div>
                <div className="comparison-content">
                  <h3>{pageContent.platform.desktop.title}</h3>
                  <p>{pageContent.platform.desktop.list_title}</p>
                  <ul>
                    <li>{pageContent.platform.desktop.list_items.list_item_1}</li>
                    <li>{pageContent.platform.desktop.list_items.list_item_2}</li>
                    <li>{pageContent.platform.desktop.list_items.list_item_3}</li>
                    <li>{pageContent.platform.desktop.list_items.list_item_4}</li>
                  </ul>
                </div>
              </div>
              <div className="column is-2 has-text-centered">
                <span className="and-icon">and</span>
              </div>
              <div className="column is-5">
                <div className="has-padding-bottom-lg">
                  <img
                    className="phone"
                    src={
                      pageContent.platform.mobile.device.source_url
                    }
                    role="presentation"
                  />
                </div>
                <div className="comparison-content">
                  <h3>{pageContent.platform.mobile.title}</h3>
                  <p>{pageContent.platform.mobile.list_title}</p>
                  <ul>
                    <li>{pageContent.platform.mobile.list_items.list_item_1}</li>
                    <li>{pageContent.platform.mobile.list_items.list_item_2}</li>
                    <li>{pageContent.platform.mobile.list_items.list_item_3}</li>
                    <li>{pageContent.platform.mobile.list_items.list_item_4}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="businesses-section section">
          <div className="container">
            <div className="columns has-padding-top-lg">
              {pageContent.businesses.business.map((biz, index) => {
                return (
                  <div
                    key={ index }
                    className="businesses-block column is-on-third"
                  >
                    <div className="block-content">
                      <h4>{biz.industry}</h4>
                      <div className="logo-container">
                        <img
                          className="business-logo"
                          src={ biz.logo.source_url }
                          alt={ biz.logo.alt_text }
                        />
                      </div>
                      <h3 className=" title">{biz.company}</h3>
                      <div className="quote">
                        <p>&ldquo;{biz.quote}&rdquo;</p>
                      </div>
                      <div className="citation">
                        <p className="strong">{biz.name}</p>
                        <p>{biz.position}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
